import React, { useEffect } from "react";
import { Link, navigate } from "gatsby";
import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import flatten from 'flat';

import { languages } from '../hooks/useLanguage';

// Layout & SEO
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = (props) => {
  let location = useLocation();
  const { t, i18n } = useTranslation();


  useEffect(() => {
    for (let i = 0; i < languages.length; i++) {
      let langURL = `/${languages[i]}/`
      if(location.pathname.includes(langURL)) {
        navigate(location.pathname.replace(langURL, '/'))
      }
    }
  }, []);

  return (
    <Layout>
      <SEO
        lang={i18n.language}
        title={`404: ${t("notFoundPage.title")}`}
      />
      <div className='page' style={{textAlign: 'center'}}>
      <h1>
        {t("notFoundPage.body.h1")}
      </h1>
      <p>
        {t("notFoundPage.body.h2")}
      </p>
      <Link
        to="/"
      >
        {t("notFoundPage.body.link")}
      </Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage;